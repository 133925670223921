import styles from './sidebar.module.scss'
import React, { FC, useEffect, useState } from "react";
import { IProps } from "../../../../types/props";
import clsx from "clsx";
import {Cross} from "../entities/cross";
import {BANNED} from "../../../../state/elum";
import {getter} from "elum-state/react";

interface ISidebar extends IProps {
    onClose: () => void;
    title?: string
    className?: {
        main?: string
        overlay?: string,
        wrapper?: string,
        header?: string
    }
    custom?: {
        bottom?: number
        zIndex?: number
    } | null
}

export const Sidebar: FC<ISidebar> = ({ children, onClose, title, className, custom = null }) => {
    const [menuHeight, setMenuHeight] = useState(custom ? custom.bottom : 0);
    const is_banned = getter(BANNED)

    console.log(is_banned)

    useEffect(() => {
        !custom && setMenuHeight(!is_banned ? document.getElementById('menu')!.clientHeight : 0);
    }, []);

    const onCloseHandler = () => {
        document.querySelector(`.${styles.sidebar_overlay}`)?.classList.add(styles.fadeOut);
        document.querySelector(`.${styles.sidebar_wrapper}`)?.classList.add(styles.sliderOut);
        setTimeout(() => { onClose(); }, 200);
    };

    return (
        <div className={clsx([styles.sidebar, className?.main])} style={{zIndex: is_banned ? 1000 : 'inherit'}}>
            <div className={clsx([styles.sidebar_overlay, className?.overlay])} onClick={onCloseHandler} />

            <div
                style={{paddingBottom: is_banned ? '20px' : 0, bottom: menuHeight, zIndex: custom?.zIndex ?? 'inherit'}}
                className={clsx([styles.sidebar_wrapper, className?.wrapper])}
            >
                <div
                    className={clsx(className?.header, styles.sidebar_header, {
                        [styles.noTitle]: !title
                    })}>
                    {
                        title &&
                        <p>{title}</p>
                    }
                    <div style={{cursor: "pointer"}} onClick={onCloseHandler}>
                        <Cross/>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};
