import {Sidebar} from "../../PopUps/Sidebar";
import {setter} from "elum-state/react";
import {SIDEBARS} from "../../../../state/elum";
import clsx from "clsx";
import styles from './index.module.scss'
import {useState} from "react";
import {game} from "../../../../Api";
import {useAdsgram, useAdsgramCallback} from "../../../../Hooks/useAdsgram";
import {useEnqueueSnackbar} from "../../../../Hooks/useSnackbar";
import {show_8542901} from "../../../../../index";

export const PromocodeSidebar = () => {

    const handleClose = () => setter(SIDEBARS, '')

    const [code, setCode] = useState('');
    const {openSnackbar} = useEnqueueSnackbar()

    const {onError, onReward} = useAdsgramCallback('promo')
    const showAd = () => {
        return show_8542901().then(onReward)
    }


    const handleOk = async () => {
        try {
            const {data} = await game.promo.check(code)
            if ('error' in data) {
                return openSnackbar({message: data.error.error_message, variant: 'error'})
            }
            handleClose()
            openSnackbar({message: 'Success!', variant: 'success'})
            showAd()

        } catch (e) {
            console.log(e)
        }

    };

    return (
        <Sidebar
            title="Enter your promocode"
            onClose={handleClose}>
            <div className={styles.promocode}>
                <input
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Enter promocode" />
                <button className={clsx(['btn_primary'])} onClick={handleOk}>Enter promocode</button>
            </div>

        </Sidebar>
    )
}