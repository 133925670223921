import {Sidebar} from "../../PopUps/Sidebar";
import {setter} from "elum-state/react";
import {ANCHOR, SIDEBARS, USER} from "../../../../state/elum";
import styles from "./index.module.scss";
import {Telegram} from "../../../../../index";

export const BlackFriday = () => {

    const onClose = async () => {
        setTimeout(() =>
                setter(SIDEBARS, '')
            , 500)
    };

    const className = {
        wrapper: styles.wrapper,
        header: styles.header
    }

    const buy = () => {
        Telegram.WebApp.openInvoice('https://t.me/$igfWcL6aMUoXFAAAviLTtYfiH6o', async (a: any) => {
            if (a === 'paid') {
                onClose()
                setter(ANCHOR, Math.random())
            }
        })
    }

    return (
        <Sidebar
            onClose={onClose}
            custom={{bottom: 0, zIndex: 999}}
            className={className}
        >
            <div onClick={buy}>
                <img src={'./assets/misc/black_friday.jpg'}/>
            </div>
        </Sidebar>
    )
}