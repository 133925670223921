import React, { useState } from 'react';
import {Input, Modal} from 'antd';
import {setter, useGlobalValue} from "elum-state/react";
import {MODALS} from "../../../../state/elum";
import styles from  './EnterPromo.module.scss'
import clsx from "clsx";
import {game} from "../../../../Api";
import {useEnqueueSnackbar} from "../../../../Hooks/useSnackbar";
import {useAdsgram, useAdsgramCallback} from "../../../../Hooks/useAdsgram";
import {show_8542901} from "../../../../../index";

export const EnterPromoModal: React.FC = () => {
    const modalOpen = useGlobalValue(MODALS) === 'promocodes'
    const [isModalOpen, setIsModalOpen] = useState(modalOpen);
    const [code, setCode] = useState('');
    const {openSnackbar} = useEnqueueSnackbar()
    const {onError, onReward} = useAdsgramCallback('promo')
    const showAd = () => {
        return show_8542901().then(onReward)
    }


    const classNames = {
        content: styles.modal_content,
        header: styles.modal_header,
        wrapper: styles.modal_wrapper,

    }

    const handleOk = async () => {
        try {
            const {data} = await game.promo.check(code)
            if ('error' in data) {
                return openSnackbar({message: data.error.error_message, variant: 'error'})
            }
            handleCancel()
            openSnackbar({message: 'Success!', variant: 'success'})
            showAd()

        } catch (e) {
            console.log(e)
        }

    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setTimeout(() => setter(MODALS, ''), 300)
    };


    return (
            <Modal
                title="Enter your promocode"
                open={isModalOpen}
                classNames={classNames}
                className={styles.modal}
                centered={false}
                onCancel={handleCancel}
                footer={[
                    <div className={styles.btn}><button onClick={handleOk} className={clsx(['btn_primary'])}>Enter</button></div>
                ]}
            >
                    <Input
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className={styles.input}
                        placeholder="Enter promo code"
                    />
            </Modal>
    );
};
