import React, { useState } from 'react';
import {setter, useGlobalValue} from "elum-state/react";
import {MODALS, USER} from "../../../../state/elum";
import styles from './SpecialTask.module.scss'
import {useTonAddress, useTonConnectModal, useTonConnectUI} from "@tonconnect/ui-react";
import {game} from "../../../../Api";
import {Modal} from "../../PopUps/Modal";
import {BalanceDiamond} from "../../../../../Assets/icons";
import clsx from "clsx";

export const SpecialTask: React.FC = () => {


    const handleCancel = () => {
        setter(MODALS, '')
    };

    const userFriendlyAddress = useTonAddress();
    const { state, open: openTon, close } = useTonConnectModal();
    const [tonConnectUI, setOptions] = useTonConnectUI();

    const make = async () => {

        if (userFriendlyAddress === '') {
            handleCancel()
            return openTon()
        }

        const paymentParams = {
            validUntil: Math.floor(Date.now() / 1000) + 3600,
            messages: [
                {
                    address: 'UQBc3ibs_bZYi_IqIEAiHJcF1hb-cPao8-vq1AcMD-cd3REq',
                    amount: '500000000',
                },
            ],
        };

        try {
            const data = await tonConnectUI.sendTransaction(paymentParams);
            if (data.boc) {
                handleCancel()
                return game.transaction.make(data)
            }
        } catch (e) {
            console.log(e)
        }


    }


    return (
        <Modal
            onClose={handleCancel}
        >
            <div className={styles.modal}>
                <div>
                    <img src={'./assets/tasks/special_task.png'}/>
                </div>

                <div className={styles.modal_text}>
                    <p>New special task</p>
                    <span>
                        <p>+ 100.000 m. and 90</p><BalanceDiamond/>
                    </span>
                </div>

                <div  className={styles.modal_btn}>
                    <button className={clsx(['btn_primary'])} onClick={make}>Got it!</button>
                </div>
            </div>

        </Modal>
    );
};
