import {setter, useGlobalValue} from "elum-state/react";
import {ANCHOR, LANGUAGE_CODE, PAGE, PAYLOAD, SIDEBARS, THEME, USER} from "../../../state/elum";
import {Timer} from "./entity/Timer";
import React from "react";
import styles from './Game.module.scss'
import {Button as Btn} from "antd";
import {button} from "./entity/texts";
import {game} from "../../../Api";
import CryptoJS from "crypto-js";
import {GameLoader} from "../../UI/Loaders/Pages/Game";
import clsx from "clsx";
import {Diamond} from "../../../../Assets/icons";
import {IS_TESTER} from "../../../Api/config.search";
import {Button} from "../../UI/Buttons";


export const TimeBlock = () => {
    const user= useGlobalValue(USER)
    const anchor = useGlobalValue(ANCHOR)
    const [loading, setLoading] = React.useState(!user.is_loaded)
    const theme = useGlobalValue(THEME)
    const langCode = useGlobalValue(LANGUAGE_CODE)
    const timeDifferance = (user.time * 1000) - Date.now()


    const getTime = async  () => {
        setLoading(true)
        try {
            const {data} = await game.users.sync(['time'])
            const time = +CryptoJS.AES.decrypt(data.time, String(user.tid)).toString(CryptoJS.enc.Utf8)
            setter(USER,(data) =>  ({...data,time}))

        } catch (e) {

        } finally {
            setLoading(false)
        }
    }



    React.useEffect(() => {
        if (user.is_loaded) {
            getTime()
        }
    },[user.is_loaded, anchor])



    if (!user.is_loaded || loading) return <GameLoader/>


    const specialTask = async () => {
        //return setter(SIDEBARS, 'test_sidebar')

        setter(SIDEBARS, 'task')
        setter(PAYLOAD, {id: 95, payload: 'make:transaction'})
    }

    return (
        <div className={styles.game}>
            <Timer timeDiff={timeDifferance} theme={theme} styles={styles} langCode={langCode}/>
            <div className={styles.game_buttons}>
                {
                    user.special_task_state < 3 ?
                        <Button.CometBorder onClick={specialTask} className={styles.animatedButton8_content}>
                            <p>Special task</p>
                        </Button.CometBorder>
                        :
                        <Btn type='primary' className={styles.game_button}
                             onClick={() => setter(SIDEBARS, 'add_time')}>{button.add_time[langCode]}</Btn>
                }
                {IS_TESTER &&
                    <button className={clsx([styles.game_button, 'btn_primary'])} onClick={() => setter(PAGE, 'drop')}>
                        <Diamond/>
                    </button>
                }
            </div>

        </div>
    )
}
