import {atom} from "elum-state/react";
import {langCode, theme} from "../Api/config.search";
import {IUser} from "../types/objects";

export const THEME = atom<'dark' | 'light'>({
    key: 'theme',
    default: 'dark'
})

const lang = langCode === 'ru' ? 'ru' : 'en'

export const LANGUAGE_CODE = atom<typeof lang>({
    key: 'language_code',
    default: 'en'
})

export type Pages =
    // pages
    'top' | 'game' | 'friends' | 'earn' | 'drop'
export type IAllPages = Pages | 'casino'
export const PAGE = atom<IAllPages>({
    key: 'page',
    default: 'game'
})

export const USER = atom<IUser>({
    key: 'user',
    default: {
        is_loaded: false,
        tid: 0,
        time: 0,
        level: 0,
        id: 0,
        first_name: '',
        last_name: '',
        username: '',
        language_code: '',
        is_premium: false,
        allows_write_to_pm: false,
        photo: '',
        ton_address: '',
        special_task_state: 0
    }
})

export type ISidebars = '' | 'daily_bonus' | 'task' | 'donuts' | 'add_time' | 'special_task' | 'tapp_task' | 'test_sidebar' | 'promocodes' | 'black_friday' | 'special_task_2'
export const SIDEBARS = atom<ISidebars>({
    key: 'sidebars',
    default: ''
})

export type IModals = '' | 'promocodes' | 'special_task'
export const MODALS = atom<IModals>({
    key: 'modals',
    default: ''

})

export const PAYLOAD = atom<any>({
    key: 'payload',
    default: {}
})


export const ANCHOR = atom({
    key: 'anchor',
    default: 0
})

export type TBANNED = 'banned' | 'time_end'
export const BANNED = atom<false | TBANNED>({
    key: 'banned',
    default: false
})


export const SHOWED_SPECIAL_TASK = atom({
    key: 'showed_special_task',
    default: false
})
